import React from 'react';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { getPublicImageURL } from 'src/utils/ImageUtils';
import { RegularCardShadow } from 'src/theme/shadows';
import * as Colors from 'src/theme/colors';
import RowDivider from 'src/components/RowDivider';
import BaseTypography from 'src/components/Text/BaseTypography';

import { AuthenticatedPortalDataType } from 'src/components/Onboarding/Login/onboardingLoginTypes';
import { RegularCardBox } from 'src/components/Cards/RegularCardBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTitleText: {
      color: Colors.GraySmall,
    },
    workspacesGroupContainer: {
      flexDirection: 'column',
      '& >div:not(:last-child)': {
        marginBottom: theme.spacing(2.5),
      },
    },
    portalListContainer: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: RegularCardShadow,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2.5),
      boxSizing: 'border-box',
      margin: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: 460,
      },
      [theme.breakpoints.down('xs')]: {
        width: 340,
      },
    },
    portalListItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& img': {
        margin: theme.spacing(1),
      },
    },
    portalInfoContainer: {
      flex: 1,
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 3),
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0, 2),
      },
    },
    portalInfoText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

interface OnboardingWorkspaceSelectProps {
  authenticatedPortals: Record<
    string,
    Record<string, AuthenticatedPortalDataType>
  >;
  onWorkspaceSelected: (input: AuthenticatedPortalDataType) => void;
}

/**
 * @deprecated This component will be removed once we have the new auth design released
 */
export const OnboardingWorkspaceSelect: React.FC<
  OnboardingWorkspaceSelectProps
> = ({ authenticatedPortals, onWorkspaceSelected }) => {
  const classes = useStyles();

  return (
    <>
      <Box mt={2} mb={6.25} textAlign="center" px={{ xs: 2, sm: 0 }}>
        <BaseTypography fontType="24Medium">
          These portals are linked to your account
        </BaseTypography>
        <BaseTypography className={classes.subTitleText}>
          You can open them or create a new one
        </BaseTypography>
      </Box>
      <div className={classes.workspacesGroupContainer}>
        {Object.entries(authenticatedPortals).map(([email, portals]) => (
          <RegularCardBox key={email} className={classes.portalListContainer}>
            <BaseTypography
              fontType="13Medium"
              className={classes.portalInfoText}
            >
              {email}
            </BaseTypography>
            <RowDivider mt={1.5} mb={1.5} />
            {Object.entries(portals).map(([portalId, data], index) => (
              <React.Fragment key={portalId}>
                <div className={classes.portalListItem}>
                  <img
                    width={34}
                    height={34}
                    src={getPublicImageURL(data.iconUrl)}
                    alt="logo"
                  />
                  <div className={classes.portalInfoContainer}>
                    <BaseTypography
                      fontType="15Medium"
                      className={classes.portalInfoText}
                    >
                      {data.name}
                    </BaseTypography>
                  </div>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => onWorkspaceSelected(data)}
                  >
                    Open
                  </Button>
                </div>
                {index !== Object.keys(portals).length - 1 && (
                  <RowDivider mt={1.5} mb={1.5} />
                )}
              </React.Fragment>
            ))}
          </RegularCardBox>
        ))}
      </div>
      <Box mt={3} display="flex" flexDirection="column" alignItems="center">
        <Button href="/add-account">
          <BaseTypography className={classes.subTitleText}>
            Log in to another account
          </BaseTypography>
        </Button>
      </Box>
    </>
  );
};

import React from 'react';
import { Auth, I18n } from 'aws-amplify';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { removeSpaceFromString } from 'src/utils/StringUtils';
import { OnboardingButtonContainer } from 'src/components/Onboarding/OnboardingForms/OnboardingButtonContainer';
import {
  BaseTextField,
  SharpOutlinedTextField,
} from 'src/components/TextField';
import { PasswordInputValidation, SignInWithGoogle } from 'src/utils/AuthUtils';
import { ContextProps, FlagsContext, RouteContext } from 'src/context';
import OnboardingButton from 'src/components/Onboarding/OnboardingButton';
import { objectWithProperties } from 'src/components/AwsAmplify/common/constants';
import UsersClient from 'src/clients/UsersClient';
import { OnboardingAuthLayoutProps } from 'src/components/Onboarding/Login/OnboardingAuthLayout';
import { OnboardingLoginFormFooterActions } from 'src/components/Onboarding/OnboardingForms/OboardingLoginFormFooterActions';
import { FormInputsContainer } from 'src/components/Onboarding/OnboardingForms/OnboardingLoginForm';
import RowDivider from 'src/components/RowDivider';
import MemoBaseTypography from 'src/components/Text/BaseTypography';
import { GoogleAuthButton } from 'src/components/UI/Buttons/GoogleAuthButton';
import { OnboardingFormError } from 'src/components/Onboarding/OnboardingForms/OnboardingFormError';
import { getFormErrorText } from 'src/components/Onboarding/OnboardingForms/CreatePortalForm';

const schema = Yup.object()
  .strict(true)
  .shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: PasswordInputValidation,
  });

interface FormValues {
  email: string;
  password: string;
}

export interface OnboardingRegisterProps extends OnboardingAuthLayoutProps {
  authUser: Record<any, any> | null;
}

export const OnboardingRegisterForm: React.FC<OnboardingRegisterProps> = ({
  authUser,
  onAuthComplete,
}) => {
  const context: ContextProps = React.useContext(RouteContext);
  const initialEmail = context?.query?.email || '';
  const { GoogleLoginForInternalUser } = React.useContext(FlagsContext);

  // custom state is used to redirect the user after succuessfull login
  // this will add a action param to the login page, which triggers the
  // check for logged in user and completes the login flow
  // this is done for an activate account page, so we make sure we have a portal to
  // login in to which is the next param
  const customState =
    context?.query?.next &&
    `/login?${new URLSearchParams({
      ...context.query,
      action: 'confirm-login',
    } as Record<string, string>).toString()}`;

  const initialValues: FormValues = {
    email: initialEmail || '',
    password: '',
  };

  const handleActivateAccount = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    const user = authUser;
    setSubmitting(true);
    try {
      const { password } = values;

      if (!user || !password) return;

      const { requiredAttributes } = user.challengeParam;
      const attrs = objectWithProperties(values, requiredAttributes);

      const res = await Auth.completeNewPassword(user, password, attrs);
      setSubmitting(false);
      if (res) {
        await UsersClient.setAppUserCookie(
          res,
          'workspace_login',
          'workspaces',
          values.email,
        );
      }
      onAuthComplete();
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error?.message || 'Unexpected Error');
      }
    }
  };

  const TextFieldsComponent = GoogleLoginForInternalUser
    ? SharpOutlinedTextField
    : BaseTextField;

  const OnboardingBtnContainerComponent = GoogleLoginForInternalUser
    ? React.Fragment
    : OnboardingButtonContainer;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleActivateAccount}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit} data-testid="testingform">
            <FormInputsContainer>
              {GoogleLoginForInternalUser && (
                <>
                  <GoogleAuthButton
                    onClick={() => SignInWithGoogle(customState || 'signup')}
                    noBorderRadius
                  />
                  <RowDivider mt={3.25} mb={0.75}>
                    <MemoBaseTypography fontType="10Medium">
                      OR
                    </MemoBaseTypography>
                  </RowDivider>
                </>
              )}
              <TextFieldsComponent
                sizeVariant="tall"
                id="login-username"
                autoFocus={!GoogleLoginForInternalUser && !initialValues.email}
                fullWidth
                type="text"
                InputProps={{
                  'data-testid': 'register-emailInput',
                }}
                key="email"
                name="email"
                variant="outlined"
                onBlur={handleBlur}
                onInput={handleChange}
                value={values.email ? removeSpaceFromString(values.email) : ''}
                label={I18n.get('Work email')}
                placeholder={I18n.get('Work email')}
                error={Boolean(touched.email && errors.email)}
                helperText={(touched.email && errors.email) || ' '}
                autoComplete="off"
                disabled={Boolean(initialValues.email)}
              />
              <TextFieldsComponent
                sizeVariant="tall"
                id="login-password"
                fullWidth
                type="password"
                InputProps={{
                  'data-testid': 'register-passwordInput',
                }}
                key="password"
                name="password"
                variant="outlined"
                onBlur={handleBlur}
                onInput={handleChange}
                value={values.password}
                label={I18n.get('Set a password')}
                error={Boolean(touched.password && errors.password)}
                helperText={
                  (!GoogleLoginForInternalUser &&
                    touched.password &&
                    errors.password) ||
                  ''
                }
                autoComplete="off"
                autoFocus={
                  !GoogleLoginForInternalUser && Boolean(initialValues.email)
                }
                placeholder={
                  GoogleLoginForInternalUser ? I18n.get('Set a password') : ''
                }
              />

              {GoogleLoginForInternalUser && (
                <OnboardingFormError
                  errorText={getFormErrorText(errors, touched)}
                />
              )}

              <OnboardingBtnContainerComponent>
                <OnboardingButton
                  type="submit"
                  isLoading={isSubmitting}
                  data-testid="submit-button"
                  color="primary"
                  variant="contained"
                  fullWidth
                  htmlId="create-account-submit-button"
                >
                  Create account
                </OnboardingButton>
              </OnboardingBtnContainerComponent>
            </FormInputsContainer>
          </form>
        )}
      </Formik>
      <OnboardingLoginFormFooterActions showOnboardingLoginActions={false} />
    </>
  );
};

import React from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { getPublicImageURL } from 'src/utils/ImageUtils';
import * as Colors from 'src/theme/colors';
import RowDivider from 'src/components/RowDivider';
import BaseTypography from 'src/components/Text/BaseTypography';

import { AuthenticatedPortalDataType } from 'src/components/Onboarding/Login/onboardingLoginTypes';
import { OnboardingFormCard } from 'src/components/Onboarding/OnboardingFormCard';
import { OnboardingFormCardTitle } from 'src/components/Onboarding/OnboardingFormCardTitle';

const WORKSPACE_CARD_WIDTH = 432;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '::-webkit-scrollbar-track': {
        '-webkit-border-radius': '10px',
        borderRadius: '10px',
      },

      '::-webkit-scrollbar-thumb': {
        '-webkit-border-radius': '10px',
        'border-radius': '10px',
        background: Colors.ScrollbarColor,
      },
    },
    titleContainer: {
      textAlign: 'center',
      marginBottom: theme.spacing(5),
    },
    subTitleText: {
      color: Colors.GraySmall,
    },
    listWrapper: {
      [theme.breakpoints.up('sm')]: {
        marginTop: 102,
        marginBottom: 102,
        maxHeight: 'calc(100vh - 204px)',
        overflowY: 'auto',
        width: WORKSPACE_CARD_WIDTH,
      },
    },

    portalListItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& img': {
        margin: theme.spacing(1),
      },
    },
    portalInfoContainer: {
      flex: 1,
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 3),
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0, 2),
      },
    },
    portalInfoText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    linksButtons: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: theme.spacing(1),
      marginTop: theme.spacing(5.5),
    },
    workspaceContainer: {
      marginBottom: theme.spacing(4.5),
    },
  }),
);

interface OnboardingWorkspaceSelectProps {
  authenticatedPortals: Record<
    string,
    Record<string, AuthenticatedPortalDataType>
  >;
  onWorkspaceSelected: (input: AuthenticatedPortalDataType) => void;
}

export const OnboardingWorkspaceSelectV2: React.FC<
  OnboardingWorkspaceSelectProps
> = ({ authenticatedPortals, onWorkspaceSelected }) => {
  const classes = useStyles();

  return (
    <OnboardingFormCard className={classes.listWrapper}>
      <OnboardingFormCardTitle
        title=" These workspaces are linked to your account"
        subTitle="You can open them or create a new one"
      />
      {Object.entries(authenticatedPortals).map(([email, portals]) => (
        <div className={classes.workspaceContainer} key={email}>
          <BaseTypography
            fontType="13Medium"
            className={classes.portalInfoText}
          >
            {email}
          </BaseTypography>
          <RowDivider mt={1.5} mb={1.5} />
          {Object.entries(portals).map(([portalId, data]) => (
            <React.Fragment key={portalId}>
              <div className={classes.portalListItem}>
                <img
                  width={34}
                  height={34}
                  src={getPublicImageURL(data.iconUrl)}
                  alt="logo"
                />
                <div className={classes.portalInfoContainer}>
                  <BaseTypography
                    fontType="15Medium"
                    className={classes.portalInfoText}
                  >
                    {data.name}
                  </BaseTypography>
                </div>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onWorkspaceSelected(data)}
                >
                  Open
                </Button>
              </div>
              <RowDivider mt={1.5} mb={1.5} />
            </React.Fragment>
          ))}
        </div>
      ))}

      <div className={classes.linksButtons}>
        <Button href="/add-account">
          <BaseTypography className={classes.subTitleText}>
            Log in to another account
          </BaseTypography>
        </Button>

        <Button href="/add-account">
          <BaseTypography className={classes.subTitleText}>
            Create a new workspace
          </BaseTypography>
        </Button>
      </div>
    </OnboardingFormCard>
  );
};

import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { FormikHelpers, FormikProps } from 'formik';
import OnboardingButton from 'src/components/Onboarding/OnboardingButton';
import UsersClient from 'src/clients/UsersClient';
import {
  OnboardingLoginForm,
  OnboardingFormValues,
} from 'src/components/Onboarding/OnboardingForms';
import BaseTypography from 'src/components/Text/BaseTypography';
import { FlagsContext, PortalConfigContext, RouteContext } from 'src/context';
import { GetSignInMetadata, GetSignInMetadataInput } from 'src/utils/AuthUtils';
import { removeSpaceToLowerCase } from 'src/utils/StringUtils';
import {
  OnboardingLoginChallengeForm,
  OnboardingLoginChallengeFormProps,
} from 'src/components/Onboarding/OnboardingForms/OnboardingLoginChallengeForm';
import {
  INVALID_CODE,
  MFA_INPUT_DESCRIPTION,
  MFA_INPUT_TITLE,
  SESSION_EXPIRY_ERR_MSG,
} from 'src/constants/authConsts';
import { OnboardingFormCard } from 'src/components/Onboarding/OnboardingFormCard';
import { OnboardingStepAvatar } from 'src/components/Onboarding/OnboardingStepAvatar';
import { ensureApiError } from 'src/utils/Errors';

interface queryParams {
  email?: string;
  auth?: string;
}

interface CustomChallengeParams {
  email: string;
  preferred_username: string;
  sub: string;
  USERNAME: string;
  type: 'CUSTOM_CHALLENGE';
}

interface MfaChallengeParams {
  type: 'SOFTWARE_TOKEN_MFA';
  friendlyDeviceName?: string;
  userId?: string;
  email: string;
}

interface OnboardingAuthProps {
  onAuthComplete: () => void;
  user?: CognitoUser;
  mfaChallengeParam?: MfaChallengeParams;
}

export const OnboardingAuth: React.FC<OnboardingAuthProps> = ({
  onAuthComplete,
  user,
  mfaChallengeParam,
}) => {
  // store sign in user to be used in challenge response. Use ref to store the instance
  // of cognito user to submit custom auth challenge.
  const signInUserRef = React.useRef<CognitoUser | undefined>(user);
  // store challenge params in sign-in response to be used in challenge response
  const [challengeParam, setChallengeParam] = React.useState<
    CustomChallengeParams | MfaChallengeParams | undefined
  >(mfaChallengeParam);

  // check email/password query params to auto-fill login form
  const { query }: { query: queryParams } = React.useContext(RouteContext);
  const portalConfig = React.useContext(PortalConfigContext);

  const [submitting, setSubmitting] = React.useState(false);
  const onboardingFormRef =
    React.useRef<FormikHelpers<OnboardingLoginChallengeFormProps>>(null);

  const loginChallengeFormRef =
    React.useRef<FormikProps<OnboardingFormValues>>(null);

  /**
   * Handle the completed sign-in process. This will set the cookie and local storage
   * for the user so that it can be used in the next steps of the login flow (e.g. workspace select)
   * @param user user returned in sign-in response
   * @param userEmail email as typed by user
   */
  const completeSignIn = async (cognitoUser: any, userEmail: string) => {
    // The sign-in method should use the email as typed by user
    // to handle different login settings (backward compatibility, with existing pools)
    // but we need to use the email in lowercase without spaces to set the cookie and workspaces
    const email = removeSpaceToLowerCase(userEmail);
    await UsersClient.setAppUserCookie(
      cognitoUser,
      'workspace_login',
      'workspaces',
      email,
    );
    window.localStorage.setItem(
      `CognitoIdentityServiceProvider.${portalConfig.AWS.Auth.userPoolWebClientId}.LastAuthUser`,
      cognitoUser.username,
    );

    // The flag we set here is used to ensure that analytics for this user session aren't stored
    // since any actions taken by a Copilot user should not skew the data for the workspace.
    if (window.location.pathname.includes('/admin')) {
      window.localStorage.setItem(`proxy#${cognitoUser.username}`, 'true');
    }

    onAuthComplete();
  };

  /**
   * Handle the submit event from the login form. This will start the login process which
   * will either return a challenge response or a successful login response. When there is
   * a challenge response, we keep track of the sign in user in ref to later submit the challenge code.
   * Once the challenge is completed, this method is called again with the challenge response.
   * @param submitValues values from the login form
   */
  const handleSubmitDone = async (submitValues: OnboardingFormValues) => {
    setSubmitting(true);
    const { email: inputEmail, password } = submitValues;
    try {
      if (
        signInUserRef.current &&
        challengeParam &&
        submitValues.challengeResponse
      ) {
        let cognitoUser;
        if (challengeParam.type === 'SOFTWARE_TOKEN_MFA') {
          cognitoUser = await Auth.confirmSignIn(
            signInUserRef.current,
            submitValues.challengeResponse,
            challengeParam.type,
          );
        } else {
          // when there is a challenge response, we need to complete the login process
          // by sending the challenge response
          cognitoUser = await Auth.sendCustomChallengeAnswer(
            signInUserRef.current,
            submitValues.challengeResponse,
          );
        }

        completeSignIn(cognitoUser, inputEmail);
      } else {
        // when there is no challenge response, we need to start the login process
        const cognitoUser = await Auth.signIn(
          inputEmail,
          password,
          GetSignInMetadata(query as GetSignInMetadataInput),
        );

        console.info('start sign-in result', cognitoUser);
        // check if custom_auth challenge is required show input for code
        if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
          signInUserRef.current = cognitoUser;
          setChallengeParam(cognitoUser.challengeParam);
          return false;
        }
        if (cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
          signInUserRef.current = cognitoUser;
          setChallengeParam({
            type: cognitoUser.challengeName,
            email: inputEmail,
            ...cognitoUser.challengeParam,
          });
          return false;
        }

        completeSignIn(cognitoUser, inputEmail);
      }
    } catch (err) {
      const e = ensureApiError(err);
      let { message } = e;
      try {
        if (message.includes('UserMigration failed')) {
          message = 'Invalid email or password';
        } else if (message.includes('session is expired')) {
          // expiry can happen when a login challenge is found
          // and not answered within 3 minutes
          message = SESSION_EXPIRY_ERR_MSG;
          loginChallengeFormRef.current?.setFieldError(
            'challengeResponse',
            message,
          );
          return;
        } else if (e.code === 'CodeMismatchException') {
          message = INVALID_CODE;
          loginChallengeFormRef.current?.setFieldError(
            'challengeResponse',
            message,
          );
          return;
        }
      } catch (ex) {
        console.error(ex, 'error parsing error message');
      }
      onboardingFormRef.current?.setFieldError('password', message);
    }

    setSubmitting(false);
    // return false to skip track event in login form that is meant for portal onboarding
    return false;
  };

  const buttonRenderer = React.useCallback(
    () => (
      <OnboardingButton
        type="submit"
        htmlId="login-btn"
        color="primary"
        variant="contained"
        isLoading={submitting}
        fullWidth
      >
        Sign in
      </OnboardingButton>
    ),
    [submitting],
  );
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const Container = GoogleLoginForInternalUser
    ? OnboardingFormCard
    : React.Fragment;
  return (
    <Container>
      <Box
        mt={GoogleLoginForInternalUser ? 0 : 2}
        mb={GoogleLoginForInternalUser ? 0 : 3.75}
      >
        <OnboardingStepAvatar />
      </Box>
      <Box
        mt={GoogleLoginForInternalUser ? 1.5 : 2}
        textAlign="center"
        px={{ xs: 2, sm: 0 }}
      >
        <BaseTypography
          fontType={GoogleLoginForInternalUser ? '18Medium' : '24Medium'}
        >
          {challengeParam && challengeParam.type === 'SOFTWARE_TOKEN_MFA'
            ? MFA_INPUT_TITLE
            : 'Log in to Copilot'}
        </BaseTypography>
      </Box>
      {challengeParam && challengeParam.type === 'SOFTWARE_TOKEN_MFA' && (
        <Box textAlign="center" px={{ xs: 2, sm: 0 }}>
          <BaseTypography fontType="13Medium">
            {MFA_INPUT_DESCRIPTION}
          </BaseTypography>
        </Box>
      )}
      <Box
        mb={{ xs: 0, sm: GoogleLoginForInternalUser ? 0 : 2 }}
        mt={GoogleLoginForInternalUser ? 5 : 6.25}
      >
        {challengeParam ? (
          <OnboardingLoginChallengeForm
            buttonLabel=""
            initialValues={{
              email: challengeParam.email,
              password: '',
              challengeResponse: '',
              name: '',
              phone: '',
              foundBy: '',
              foundByCustom: '',
              companyName: '',
              portalUrl: '',
              industry: '',
              industryCustom: '',
              companySize: '',
              role: '',
              messagingPageDisabled: false,
              filesPageDisabled: false,
              formsPageDisabled: false,
              paymentsModuleDisabled: false,
              resourceSectionDisabled: false,
              marketingSiteEnabled: false,
              signoutRedirectURL: '',
              anonymousId: '',
              clientCount: '',
            }}
            ref={loginChallengeFormRef}
            handleSubmitDone={handleSubmitDone}
            buttonRenderer={buttonRenderer}
            challengeName={challengeParam.type}
            handleClickSkip={() => {}}
            onAnalyticsEventTracked={() => {}}
            trackedEvents={{}}
          />
        ) : (
          <OnboardingLoginForm
            ref={onboardingFormRef}
            buttonLabel=""
            initialValues={{
              email: query.email || '',
              password: query.auth || '',
              name: '',
              phone: '',
              foundBy: '',
              foundByCustom: '',
              companyName: '',
              portalUrl: '',
              industry: '',
              industryCustom: '',
              companySize: '',
              role: '',
              messagingPageDisabled: false,
              filesPageDisabled: false,
              formsPageDisabled: false,
              paymentsModuleDisabled: false,
              resourceSectionDisabled: false,
              marketingSiteEnabled: false,
              signoutRedirectURL: '',
              anonymousId: '',
              clientCount: '',
            }}
            handleSubmitDone={handleSubmitDone}
            showOnboardingLoginActions
            buttonRenderer={buttonRenderer}
            handleClickSkip={() => {}}
            onAnalyticsEventTracked={() => {}}
            trackedEvents={{}}
          />
        )}
      </Box>
    </Container>
  );
};

import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { OnboardingPageLayout } from 'src/components/Onboarding/OnboardingPageLayout';
import BaseTypography from 'src/components/Text/BaseTypography';
import {
  MfaFormProps,
  OnboardingForgotPasswordForm,
} from 'src/components/Onboarding/OnboardingForms/OnboardingForgotPasswordForm';
import { OnboardingAuthLayoutProps } from 'src/components/Onboarding/Login/OnboardingAuthLayout';
import { OnboardingFormCard } from 'src/components/Onboarding/OnboardingFormCard';
import { FlagsContext } from 'src/context';
import { CopilotAvatar } from 'src/components/UI/Avatars/CopilotAvatar';

type OnboardingForgotPasswordPageProps = OnboardingAuthLayoutProps &
  MfaFormProps;

export const OnboardingForgotPasswordPage = ({
  onAuthComplete,
  onMfaRequired,
}: OnboardingForgotPasswordPageProps) => {
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const Container = GoogleLoginForInternalUser
    ? OnboardingFormCard
    : OnboardingPageLayout;
  const copilotAvatarProps = GoogleLoginForInternalUser ? {} : { size: 100 };

  return (
    <Container>
      <Box
        mt={GoogleLoginForInternalUser ? 0 : 2}
        mb={GoogleLoginForInternalUser ? 0 : 3.75}
      >
        <CopilotAvatar {...copilotAvatarProps} />
      </Box>
      <Box
        mt={GoogleLoginForInternalUser ? 1.5 : 2}
        mb={GoogleLoginForInternalUser ? 5 : 6.25}
        textAlign="center"
        px={{ xs: 2, sm: 0 }}
      >
        <BaseTypography
          fontType={GoogleLoginForInternalUser ? '18Medium' : '24Medium'}
        >
          Reset your password
        </BaseTypography>
      </Box>
      <Box
        mb={{ xs: 0, sm: GoogleLoginForInternalUser ? 0 : 2 }}
        mt={GoogleLoginForInternalUser ? -2.5 : 0}
      >
        <OnboardingForgotPasswordForm
          onAuthComplete={onAuthComplete}
          onMfaRequired={onMfaRequired}
        />
      </Box>
    </Container>
  );
};

import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React, { useContext } from 'react';
import { ContextProps, FlagsContext, RouteContext } from 'src/context';
import { GetSignInMetadata, GetSignInMetadataInput } from 'src/utils/AuthUtils';
import { LoadingWrapper } from 'src/components/Loading';
import BaseTypography from 'src/components/Text/BaseTypography';
import { OnboardingRegisterForm } from 'src/components/Onboarding/OnboardingForms/OnboardingRegisterForm';
import { OnboardingPageLayout } from 'src/components/Onboarding/OnboardingPageLayout';
import { OnboardingAuthLayoutProps } from 'src/components/Onboarding/Login/OnboardingAuthLayout';
import { OnboardingFormCard } from 'src/components/Onboarding/OnboardingFormCard';
import { CopilotAvatar } from 'src/components/UI/Avatars/CopilotAvatar';

type OnboardingRegisterPageProps = OnboardingAuthLayoutProps;

export const OnboardingRegisterPage: React.FC<OnboardingRegisterPageProps> = ({
  onAuthComplete,
}) => {
  const [pageLoading, setPageLoading] = React.useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] =
    React.useState(false);
  const [authUser, setAuthUser] = React.useState(null);
  const context: ContextProps = React.useContext(RouteContext);

  /**
   * Get the initial email state which will be used to auto sign in the user
   */
  const [initialEmail] = React.useState(context?.query?.email || '');

  const handlePageLoading = (isLoading: boolean) => setPageLoading(isLoading);

  // auto sign in user with the email and password available in the query
  const autoSignIn = async (userEmail: string, password: string) => {
    try {
      handlePageLoading(true);
      const username = userEmail;
      const user = await Auth.signIn(
        username,
        password,
        GetSignInMetadata(context.query as GetSignInMetadataInput),
      );

      handlePageLoading(false);
      if (user) {
        setAuthUser(user);
        if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // once the user is logged using query email and password
          // show register form to update the password
          setIsNewPasswordRequired(true);
        }
      }
    } catch (error) {
      handlePageLoading(false);
    }
  };

  // if we have email and password in query, auto sign in user
  React.useEffect(() => {
    const password = context?.query?.auth || '';
    if (initialEmail && password) autoSignIn(initialEmail, password);
  }, []);

  const theme = useTheme();
  const isNonMobileWindow = useMediaQuery(theme.breakpoints.up('sm'));
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const CardContainer = GoogleLoginForInternalUser
    ? OnboardingFormCard
    : React.Fragment;
  const copilotAvatarProps = GoogleLoginForInternalUser ? {} : { size: 100 };

  return (
    <OnboardingPageLayout>
      <LoadingWrapper
        hideContentWhileLoading
        isLoading={pageLoading}
        {...(GoogleLoginForInternalUser && isNonMobileWindow
          ? { color: 'white' }
          : {})}
      >
        <CardContainer>
          {isNewPasswordRequired && (
            <>
              <Box
                mt={GoogleLoginForInternalUser ? 0 : 2}
                mb={GoogleLoginForInternalUser ? 0 : 3.75}
              >
                <CopilotAvatar {...copilotAvatarProps} />
              </Box>
              <Box
                mt={GoogleLoginForInternalUser ? 1.5 : 2}
                mb={GoogleLoginForInternalUser ? 5 : 6.25}
                textAlign="center"
                px={{ xs: 2, sm: 0 }}
              >
                <BaseTypography
                  fontType={
                    GoogleLoginForInternalUser ? '18Medium' : '24Medium'
                  }
                >
                  Activate your account
                </BaseTypography>
              </Box>
              <Box mb={{ xs: 0, sm: GoogleLoginForInternalUser ? 0 : 2 }}>
                <OnboardingRegisterForm
                  authUser={authUser}
                  onAuthComplete={onAuthComplete}
                />
              </Box>
            </>
          )}
        </CardContainer>
      </LoadingWrapper>
    </OnboardingPageLayout>
  );
};

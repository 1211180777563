import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import BaseTypography from 'src/components/Text/BaseTypography';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(1.5),
  },
}));
export const OnboardingFormCardTitle: React.FC<{
  title: string;
  subTitle?: string;
}> = ({ title, subTitle, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <BaseTypography fontType="18Medium">{title}</BaseTypography>
      {subTitle && <BaseTypography>{subTitle}</BaseTypography>}
      {children}
    </div>
  );
};
